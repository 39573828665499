.toptech-section {
    padding: 3rem;
}
.toptech-heading, .toptech-card-covers h3 {
    color: #000;

    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.toptech-txt {
    color: #000;

    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 0;
}
.toptech-card-covers {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 2rem auto;
}
.toptech-card-logo, .toptech-card-duration, .toptech-card-country,
.toptech-card-service, .toptech-card-description, .toptech-card-web-design {
    border-radius: 14px;
    background: #FFF;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);
    padding: 1.875rem;
    width: 100%;
    overflow: hidden;
    position: relative;
}
.toptech-card-logo {
    width: 234px;
    min-height: 255px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.toptech-card-logo img {
    width: 100%;
}
.toptech-card-web-design {
    width: 513px;
    min-height: 255px;
}
.toptech-card-web-design img {
    position: absolute;
    top: -13px;
    right: -42px;
}
.toptech-card-description {
    width: 514px;
    min-height: 282px;
}
.toptech-card-duration {
    width: 232px;
    min-height: 282px;
}
.toptech-card-duration img {
    margin-bottom: 1.8rem;
}
.toptech-card-service {
    width: 234px;
    min-height: 327px;
}
.toptech-services-img {
    width: 100%;
    margin-bottom: 2rem;
}
.toptech-card-country {
    width: 234px;
    min-height: 210px;
}
.toptech-card-country img {
    margin-top: 1rem;
}
.toptech-website-wrap {
    color: #2B2E2B;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: end;
}
.toptech-website-wrap a {
    color: #2B2E2B;

    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
}
.toptech-cardCol {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}
.toptech-card-covers h4 {
    color: #000;

    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.toptech-card-covers p {
    color: #000;

    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 171%;
    margin: 0;
}
.top-logo-web-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.top-dur-des-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}


@media screen and (max-width: 480px) {
    .toptech-section {
        padding: 3rem 1rem;
    }
    /* .width-90 {
        width: 100%;
    } */
    .toptech-card-covers, .top-logo-web-wrap, .top-dur-des-wrap {
        flex-direction: column;
    }
    .toptech-card-logo, .toptech-card-service, .toptech-card-duration, .toptech-card-country, .toptech-card-description,
    .toptech-card-web-design, .toptech-card-app {
        width: auto;
    }
    .toptech-card-duration {
        min-height: auto;
    }
}



