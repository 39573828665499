.flashlead-section {
    padding: 3rem;
}
.fl-heading, .fl-card-covers h3 {
    color: #000;

    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.fl-txt {
    color: #000;

    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 0;
}
.fl-card-covers {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 2rem auto;
}
.fl-card-logo, .fl-card-duration, .fl-card-country,
.fl-card-service, .fl-card-description, .fl-card-app {
    border-radius: 14px;
    background: #FFF;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);
    padding: 1.875rem;
    width: 100%;
    overflow: hidden;
    position: relative;
}
.fl-card-logo {
    width: 234px;
    min-height: 234px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.fl-card-logo img {
    width: 100%;
}
.fl-card-duration {
    width: 245px;
    min-height: 206px;
}
.fl-card-duration img {
    margin-bottom: 1rem;
}
.fl-card-country {
    width: 256px;
    min-height: 206px;
}
.fl-card-country img {
    margin-bottom: 1.8rem;
}
.fl-card-web-design {
    width: 236px;
    min-height: 159px;
    border-radius: 14px;
    background: #3D9389;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);
    padding: 1.5rem;
    position: relative;
    overflow: hidden;
}
.fl-card-web-design img {
    position: absolute;
    right: 0;
    bottom: 0;
}
.fl-card-service {
    width: 234px;
    min-height: 304px;
}
.fl-services-img {
    width: 100%;
    margin-bottom: 2rem;
}
.fl-card-description {
    width: 511px;
    min-height: 333px;
}
.fl-card-app {
    width: 236px;
    min-height: 378px;
}
.fl-app-card-img-wrap img {
    position: absolute;
    right: 0px;
    bottom: 0;
}
.fl-appstore-wrap {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 0.4rem;
}
.fl-website-wrap {
    color: #2B2E2B;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: end;
}
.fl-website-wrap a {
    color: #2B2E2B;

    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
}
.duration-outer-wrap {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
}
.fl-cardCol {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}
.fl-card-covers h4 {
    color: #000;

    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.fl-card-covers p {
    color: #000;

    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 171%;
    margin: 0;
}
.fl-card-web-design h4 {
    color: #fff;
}


@media screen and (max-width: 480px) {
    .flashlead-section {
        padding: 3rem 1rem;
    }
    /* .width-90 {
        width: 100%;
    } */
    .fl-card-covers {
        flex-direction: column;
    }
    .fl-card-logo, .fl-card-service, .fl-card-duration, .fl-card-country, .fl-card-description,
    .fl-card-web-design, .fl-card-app {
        width: auto;
    }
}



