*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html, body {
    width: 100%;
    height: 100%;
}
.fw-1 {font-weight: 100;}
.fw-2 {font-weight: 200;}
.fw-3 {font-weight: 300;}
.fw-4 {font-weight: 400;}
.fw-5 {font-weight: 500;}
.fw-6 {font-weight: 600;}
.fw-7 {font-weight: 700;}
.fw-8 {font-weight: 800;}
.fw-9 {font-weight: 900;}


/* Global media query start */
@media screen and (min-width: 480px) {
    .hide-dk {
        display: none !important;
    }
}
@media screen and (max-width: 480px) {
    .hide-mb {
        display: none !important;
    }
}
/* Global media query end */

/* Adding Fonts Start */
/* Alexandria font family start */
@font-face {
    font-family: Alexandria;
    src: url('./assets/fonts/Alexandria-ExtraLight.ttf');
    font-weight: lighter;
}
@font-face {
    font-family: Alexandria;
    src: url('./assets/fonts/Alexandria-Light.ttf');
    font-weight: light;
}
@font-face {
    font-family: Alexandria;
    src: url('./assets/fonts/Alexandria-Regular.ttf');
    font-weight: normal;
}
@font-face {
    font-family: Alexandria;
    src: url('./assets/fonts/Alexandria-Bold.ttf');
    font-weight: bold;
}
@font-face {
    font-family: Alexandria;
    src: url('./assets/fonts/Alexandria-Black.ttf');
    font-weight: bolder;
}
/* ----------------------------------------- */
@font-face {
    font-family: Alexandria;
    src: url('./assets/fonts/Alexandria-Thin.ttf');
    font-weight: 100;
}
@font-face {
    font-family: Alexandria;
    src: url('./assets/fonts/Alexandria-ExtraLight.ttf');
    font-weight: 200;
}
@font-face {
    font-family: Alexandria;
    src: url('./assets/fonts/Alexandria-Light.ttf');
    font-weight: 300;
}
@font-face {
    font-family: Alexandria;
    src: url('./assets/fonts/Alexandria-Regular.ttf');
    font-weight: 400;
}
@font-face {
    font-family: Alexandria;
    src: url('./assets/fonts/Alexandria-Medium.ttf');
    font-weight: 500;
}
@font-face {
    font-family: Alexandria;
    src: url('./assets/fonts/Alexandria-SemiBold.ttf');
    font-weight: 600;
}
@font-face {
    font-family: Alexandria;
    src: url('./assets/fonts/Alexandria-Bold.ttf');
    font-weight: 700;
}
@font-face {
    font-family: Alexandria;
    src: url('./assets/fonts/Alexandria-ExtraBold.ttf');
    font-weight: 800;
}
@font-face {
    font-family: Alexandria;
    src: url('./assets/fonts/Alexandria-Black.ttf');
    font-weight: 900;
}
/* Alexandria font family end */
/* Almarai font family start */
@font-face {
    font-family: Almarai;
    src: url('./assets/fonts/Almarai-Light.ttf');
    font-weight: 300;
}
@font-face {
    font-family: Almarai;
    src: url('./assets/fonts/Almarai-Regular.ttf');
    font-weight: 500;
}
@font-face {
    font-family: Almarai;
    src: url('./assets/fonts/Almarai-Bold.ttf');
    font-weight: 700;
}
@font-face {
    font-family: Almarai;
    src: url('./assets/fonts/Almarai-ExtraBold.ttf');
    font-weight: 800;
}
/* Almarai font family end */
/* BRFirma font family start */
@font-face {
    font-family: BRFirma;
    src: url('./assets/fonts/BRFirma-ExtraLight.ttf');
    font-weight: lighter;
}
@font-face {
    font-family: BRFirma;
    src: url('./assets/fonts/BRFirma-Regular.ttf');
    font-weight: normal;
}
@font-face {
    font-family: BRFirma;
    src: url('./assets/fonts/BRFirma-Bold.ttf');
    font-weight: bold;
}
@font-face {
    font-family: BRFirma;
    src: url('./assets/fonts/BRFirma-Black.ttf');
    font-weight: bolder;
}
/* ----------------------------------------- */
@font-face {
    font-family: BRFirma;
    src: url('./assets/fonts/BRFirma-Thin.ttf');
    font-weight: 100;
}
@font-face {
    font-family: BRFirma;
    src: url('./assets/fonts/BRFirma-ExtraLight.ttf');
    font-weight: 200;
}
@font-face {
    font-family: BRFirma;
    src: url('./assets/fonts/BRFirma-Light.ttf');
    font-weight: 300;
}
@font-face {
    font-family: BRFirma;
    src: url('./assets/fonts/BRFirma-Regular.ttf');
    font-weight: 400;
}
@font-face {
    font-family: BRFirma;
    src: url('./assets/fonts/BRFirma-Medium.ttf');
    font-weight: 500;
}
@font-face {
    font-family: BRFirma;
    src: url('./assets/fonts/BRFirma-SemiBold.ttf');
    font-weight: 600;
}
@font-face {
    font-family: BRFirma;
    src: url('./assets/fonts/BRFirma-Bold.ttf');
    font-weight: 700;
}
@font-face {
    font-family: BRFirma;
    src: url('./assets/fonts/BRFirma-Bold.ttf');
    font-weight: 800;
}
@font-face {
    font-family: BRFirma;
    src: url('./assets/fonts/BRFirma-Black.ttf');
    font-weight: 900;
}

/* BRFirma font family end */
/* Adding Fonts End */