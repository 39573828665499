/* ------Header CSS Start-------- */
.header {
    width: 100%;
    padding: 20px;
    box-shadow: 0px -12.854px 50px 0px rgba(9, 83, 74, 0.08);
    z-index: 99;
    position: sticky;
    top: 0;
    background-color: #fff;
}
.direction-navbar {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    position: relative;
}
.nav-menu {
    position: absolute;
    right: 0;
    top: 8px;
}
.menu-wrap {
    width: 43px;
    height: 37px;
    border: 1px solid var(--GREEN, #3D9389);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.direction-menu {
    width: 70%;
}
.direction-logo-cover {
    max-width: 100px;
    width: 100%;
}
.direction-nav-cover {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 913px;
    width: 100%;
    transition: all 0.4s;
}
.nav-inner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-right: 4rem;
}
.nav-inner-wrapper a {
    color: var(--BLACK, #2B2E2B);

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
    border-radius: unset;

}
.nav-inner-wrapper a:hover {

    font-weight: 600;
    border-bottom: 2px solid var(--YELLOW, #E6FE52);
}
.nav-inner-wrapper a.selected {
    border-bottom: 2px solid var(--YELLOW, #E6FE52);
    font-weight: 600;
}
.start-now-nav {
    color: #FFF;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 12px;
    background: var(--ORANGE, #F97743);
}
.language-btn {
    color: var(--BLACK, #2B2E2B);
    font-family: Almarai;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
/* custom menu btn start */
.custom-menu-btn {
    position: absolute;
    width: 43px;
    height: 37px;
    border: 1px solid var(--GREEN, #3D9389);
    border-radius: 4px;
    right: 0;
    top: 8px;
    overflow: hidden;
    transition: transform 0.3s ease;
}
.custom-menu-btn.open {
    border: 1px solid var(--BLACK, #2B2E2B);
}
.cus-menu-spn1, .cus-menu-spn2, .cus-menu-spn3,
.cus-menu-spn4, .cus-menu-spn5, .cus-menu-spn6 {
    position: absolute;
    width: 5px;
    background-color: var(--GREEN, #3D9389);
    border-radius: 100px;
    z-index: 10;
}
.cus-menu-spn1 {
    height: 25px;
    top: 5px;
    left: 12.8px;
    transform: rotate(33deg);
    transition: transform 0.3s ease;
}
.cus-menu-spn2 {
    height: 25.7px;
    top: 5.6px;
    right: 12.8px;
    transform: rotate(326deg);
    transition: transform 0.3s ease;
}
.cus-menu-spn3 {
    height: 15px;
    top: 6.9px;
    right: 9.9px;
    transform: rotate(33deg);
    transition: transform 0.5s ease;
}
.cus-menu-spn4 {
    height: 15px;
    top: 5.9px;
    left: 8.9px;
    transform: rotate(325deg);
    transition: transform 0.5s ease;
}
.cus-menu-spn5 {
    height: 15px;
    top: 14.9px;
    right: 7.9px;
    transform: translate(14px, 24px);
    transition: transform 0.5s ease;
}
.cus-menu-spn6 {
    height: 15px;
    top: 13.9px;
    left: 8.9px;
    transform: translate(-14px, 24px);
    transition: transform 0.5s ease;
}
  
.custom-menu-btn.open .cus-menu-spn1 {
    transform: rotate(146deg);
    background-color: var(--BLACK, #2B2E2B);
    top: 4.8px;
}
.custom-menu-btn.open .cus-menu-spn2 {
    background-color: var(--BLACK, #2B2E2B);
    transform: rotate(35deg);
    top: 4.6px;
    right: 11.8px;
}
.custom-menu-btn.open .cus-menu-spn3 {
    transform: translate(14px, -21px);
}
.custom-menu-btn.open .cus-menu-spn4 {
    transform: translate(-15px, -19px);
}
.custom-menu-btn.open .cus-menu-spn5 {
    transform: rotate(145deg);
    top: 14.9px;
    right: 8px;
    z-index: 4;
}
.custom-menu-btn.open .cus-menu-spn6 {
    transform: rotate(220deg);
    top: 15px;
    z-index: 4;
}
/* custom menu btn start */

/*********** popup added ************/
.popup.confirm-details {
    width: 40%;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.10);
    z-index: 1000;
    position: fixed;
    top: 20px;
    left: 25%;
    padding: 20px;
}
.popup-title {
    color:#2B2E2B;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 156%;
}
.popup-header {
    text-align: center;
}
.close-popup {
    position: absolute;
    right: 15px;
    top: 15px;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
}
.popup-body {
    width: 70%;
    margin: 15px auto;
}
/*********** popup added ************/
@media screen and (max-width: 480px) {
    .direction-nav-cover {
        flex-direction: column;
        height: 0px;
        opacity: 0;
        overflow: hidden;
    }
    .nav-inner-wrapper {
        flex-direction: column;
        gap: 0.3rem;
        margin: 0;
    }
    .nav-btn-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .direction-navbar {
        align-items: flex-start;
        flex-direction: column;
    }
    .direction-nav-cover.open {
        height: 250px;
        opacity: 100%;
    }
    .nav-inner-wrapper {
        margin: 0;
    }
    .popup.confirm-details {
        width: 100%;
        margin: auto;
        left: auto;
    }
    .popup-body {
        width: 100%;
        margin: 15px auto;
        max-width: 305px;
    }

}
/* ------Header CSS End-------- */

/* ------Footer CSS Start-------- */
.footer {
    box-shadow: 0px -12.854px 50px 0px rgba(9, 83, 74, 0.08);
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.Footer-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 1rem auto;
}
.footer-logo-wraper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}
.footer-text {
    color: #000;

    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 0;
}
.footer-link-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6rem;
    margin: auto;
}
.footer-link-wrapper a {
    color: #1E2833;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.18px;
    text-decoration: none;
}
.footer-link-wrapper a:hover {
    color: #1E2833;

    font-weight: 600;
}
.footer-social-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.25rem;
}
@media screen and (max-width: 480px) {
    .footer {
        height: auto;
        flex-direction: column;
        padding: 1.5rem 0rem;
    }
    .Footer-wrap {
        flex-direction: column;
    }
    .footer-logo-wraper {
        justify-content: space-between;
        width: 100%;
    }
    .footer-link-wrapper {
        flex-direction: column;
        gap: 0.5rem;
        margin: 1rem;
    }
}
/* ------Footer CSS End-------- */
.header a, .footer a, .footer p,
.homepage h1, .homepage h2, .homepage h3, .homepage h4, 
.homepage h5, .homepage h6, .homepage p, .homepage a,
.aboutpage h1, .aboutpage h2, .aboutpage h3, .aboutpage h4, 
.aboutpage h5, .aboutpage h6, .aboutpage p, .aboutpage a,
.servicepage h1, .servicepage h2, .servicepage h3, .servicepage h4, 
.servicepage h5, .servicepage h6, .servicepage p, .servicepage a, .servicepage li,
.servicepage input, .servicepage select,
.portfolio-page h1, .portfolio-page h2, .portfolio-page h3, .portfolio-page h4, 
.portfolio-page h5, .portfolio-page h6, .portfolio-page p, .portfolio-page a,
.caseStudies-page h1, .caseStudies-page h2, .caseStudies-page h3, .caseStudies-page h4, 
.caseStudies-page h5, .caseStudies-page h6, .caseStudies-page p, .caseStudies-page a,
.thankyou-page h1, .thankyou-page p, .thankyou-page a
 {
    font-family: 'BRFirma' !important;
}

/* ------Homepage CSS Start-------- */
/* hero section start */
.homepage {
    background-color: #fff;
}

.hero-section {
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.hero-heading-spn {
    color: var(--BLACK, #FFF);
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 145%;
    border-radius: 18px;
    padding: 0.5rem 1.5rem;
    margin: 0 1rem;
    background: var(--GREEN, #3D9389);
}
.hero-heading {
    color: var(--BLACK, #2B2E2B);
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
}
.hero-img-wrap {
    display: flex;
    align-items: flex-start;
    justify-content: end;
    gap: 1rem;
    margin: 2rem 1rem;
    margin-bottom: 0;
}
.hero-img-wrap p {
    margin: 0;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    text-align: start;
    line-height: 153%;
    max-width: 420px;
}
/* hero section end *

/* intro section start */
.intro-wrapper {
    background-color: #F7F7F8;
    padding: 4rem 6rem 3rem;
    /* border-radius: 126px; */
}
.intro-heading-wrap {
    max-width: 988px;
    margin: auto;
}
.intro-heading {
    color: var(--BLACK, #2B2E2B);
    text-align: start;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 2rem;
}
.intro-inner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}
.what-we-do-card {
    border-radius: 34px;
    background: #FFF;
    padding: 3rem;
    padding-bottom: 2rem;
    min-height: 428px;
    max-width: 495px;
    width: 100%;
    position: relative;
}
.wedo-card-head-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}
.what-we-do-img {
    max-width: 126px;
    width: 100%;
}
.what-we-do-heading {
    color: var(--BLACK, #2B2E2B);
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0rem;
}
.what-we-do-txt {
    color: var(--BLACK, #2B2E2B);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 156%;
}
.wedo-card-link-wrap {
    position: absolute;
    bottom: 2rem;
    right: 51px;
}
.wedo-card-link-wrap a {
    color: #F97743;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    text-decoration: none;
}
/* intro section end */
/* craft section start */
.craft-main-cover {
    padding: 3rem 6rem;
}
.craft-heading-wrap {
    margin: 1.5rem auto;
    max-width: 965px;
}
.craft-heading {
    color: var(--BLACK, #2B2E2B);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 105%;
}
.craft-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
}
.craft-inner-wrap.left {
    max-width: 393px;
    width: 100%;
}
.craft-inner-wrap.right {
    max-width: 595px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1.5rem;
}
.craft-heading-spn {
    font-weight: 500;
}
.craft-card {
    border-radius: 38px;
    padding: 2.5rem 2.5rem 2rem;
    width: 100%;
    position: relative;
}
.craft-card.orange {
    background: var(--ORANGE, #F97743);
    max-width: 393px;
    min-height: 690px;
}
.craft-card.orange .craft-card-icon-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    margin-bottom: 2rem;
}
.craft-card.green {
    background: var(--GREEN, #3D9389);
    max-width: 592px;
    min-height: 341px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 1.5rem;
}
.craft-card.black {
    background: var(--BLACK, #2B2E2B);
    max-width: 592px;
    min-height: 327px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 1.5rem;
}
.craft-card-icon {
    max-width: 98px;
}
.craft-dot-icon {
    position: absolute;
    left: 0;
    top: 11px;
}
.craft-card-heading {
    color: #FFF;

    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.craft-card-txt {
    color: #FFF;

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}
.craft-link-wrap {
    position: absolute;
    right: 2rem;
    bottom: 2rem;
}
.craft-card-link {
    color: var(--YELLOW, #E6FE52);

    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    text-decoration: none;
}
/* craft section end */
/* boundaries section start */
.boundaries-section {
    min-height: 300px;
    background-image: url('/src/assets/homepage-images/bounderies-r.svg'), url('/src/assets/homepage-images/bounderies-l.svg');
    background-size: auto, auto;
    background-position: right 0%, left 0%;
    background-repeat: no-repeat;
    padding: 1rem;
}
.bound-sub-heading {
    color: var(--BLACK, #2B2E2B);

    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin: 0;
}
.bound-heading {
    color: var(--BLACK, #2B2E2B);

    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}
.bound-col-heading {
    color: var(--GREEN, #3D9389);

    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.bound-col-txt {
    color: var(--BLACK, #2B2E2B);

    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}
.bound-inn-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5rem;
    margin-top: 2rem;
}
/* boundaries section end */
/* trusted section start */
.trusted-wrap {
    background-color: #F7F7F8;
    min-height: 379px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.trusted-heading {
    color: var(--BLACK, #2B2E2B);

    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    margin-bottom: 3rem;
}
.trusted-head-spn {
    font-weight: 700;
}
.trusted-col-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}
/* trusted section end */
/* Office section start */
.office-section {
    margin-top: 3rem;
}
.office-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    width: 100%;
}
.office-txt-wrap {
    width: 26%;
}
.office-img-wrap {
    width: 50%;
}
.office-map-img {
    width: 100%;
}
.office-heading {
    color: var(--BLACK, #2B2E2B);

    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 122%;
}
.office-txt {
    color: #2B2E2B;

    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 122%;
}
.offices-list {
    color: var(--BLACK, #2B2E2B);

    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 165%;
    list-style: none;
    padding-left: 3rem;
}
.offices-list li {
    position: relative;
}
.offices-li-spn {
    position: absolute;
    left: -40px;
    top: -3px;
}
/* Office section end */
/* Challenge section start */
.challange-section {
    background-image: url('/src/assets/homepage-images/challenging-banner.svg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 522px;
    display: flex;
    align-items: center;
    padding: 2rem 10rem;
    margin-top: 3rem;
}
.challenge-main-heading {
    color: var(--BLACK, #2B2E2B);

    font-size: 50.991px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.challenge-head-spn {
    font-size: 41.72px;
}
.challenge-sub-heading {
    color: var(--GREEN, #3D9389);

    font-size: 46.355px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.challenge-head-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}
.challenge-btn-wrap {
    margin-top: 2rem;
    text-align: start;
}
.start-now-btn {
    color: #FFF;
    text-align: center;

    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
    border-radius: 31px;
    background: var(--ORANGE, #F97743);
    padding: 0.7815rem 2.5rem;
    position: relative;
}
.start-now-btn::after {
    content: url('/src/assets/homepage-images/circle-arrow.svg');
    position: absolute;
    right: -64px;
    top: -3px;
}
/* Challenge section end */
@media screen and (max-width: 480px) {
    /* *****Sec 1****** */
    .hero-heading {
        font-size: 30px;
    }
    .hero-heading-spn {
        font-size: 30px;
    }
    /* *****Sec 2****** */
    .intro-wrapper {
        background-color: #F7F7F8;
        padding: 2rem;
        border-radius: 60px;
    }
    .intro-heading {
        font-size: 30px;
        margin: 1rem;
    }
    .intro-inner-wrapper {
        flex-direction: column;
    }
    .what-we-do-card {
        padding: 2rem;
    }
    .what-we-do-img {
        max-width: 95px;
    }
    .what-we-do-heading {
        font-size: 20px;
    }
    .what-we-do-txt {
        font-size: 18px;
    }
    /* ******Sec 3******* */
    .craft-main-cover {
        padding: 1rem;
    }
    .craft-wrapper {
        gap: 1rem;
        flex-direction: column;
    }
    .craft-heading {
        font-size: 30px;
        margin: 2rem 1rem 1rem 1rem;
    }
    .craft-card {
        padding: 2rem;
        margin-bottom: 1rem;
    }
    .craft-card.orange {
        min-height: initial;
    }
    .craft-card.green, .craft-card.black {
        flex-direction: column;
        gap: 1rem;
    }
    .craft-card-heading {
        font-size: 20px;
    }
    .craft-card-txt {
        font-size: 16px;
    }
    /* ******Sec 4******* */
    .boundaries-section {
        background-size: 20%, 20%;
        background-position: right 28%, left 80%;
    }
    .bound-sub-heading {
        font-size: 30px;
    }
    .bound-heading {
        font-size: 35px;
    }
    .bound-inn-wrap {
        flex-direction: column;
        text-align: center;
        gap: 1.5rem;
    }
    .bound-col-heading {
        font-size: 30px;
    }
    .bound-col-txt {
        font-size: 18px;
    }
    /* ******Sec 5******* */
    .trusted-wrap {
        flex-direction: column;
        border-radius: 60px;
        margin-top: 3rem;
        padding: 3rem 1rem;
    }
    .trusted-heading {
        font-size: 30px;
        margin-bottom: 2rem;
    }
    .trusted-col-wrap {
        flex-direction: column;
    }
    /* ******Sec 6******* */
    .office-wrapper {
        flex-direction: column;
        gap: 2rem;
        padding: 2rem 2.8rem;
        align-items: start;
    }
    .office-heading {
        font-size: 30px;
    }
    .offices-list {
        font-size: 24px;
    }
    .office-txt-wrap, .office-img-wrap {
        width: 100%;
    }
    /* ******Sec 7******* */
    .challange-section {
        background: #ecff79;
        border-top-left-radius: 35px;
        border-top-right-radius: 35px;
        padding: 3rem 1.5rem;
        flex-direction: column;
        justify-content: center;
        height: auto;
        text-align: center;
    }
    .challenge-head-wrap {
        flex-direction: column;
        gap: 0rem;
    }
    .challenge-head-spn {
        font-size: 28px;
    }
    .challenge-main-heading {
        font-size: 35px;
    }
    .challenge-sub-heading {
        font-size: 36px;
    }
    .challenge-btn-wrap {
        margin-left: 1rem;
        text-align: center;
    }
    .start-now-btn {
        font-size: 20px;
    }
    .start-now-btn::after {
        display: none;
    }
  }
/* ------Homepage CSS End-------- */

/* ------About CSS Start-------- */
/* hero section start */
.aboutpage {
    background-color: #F9F9F9;
}
.about-hero-section {
    background-image: url('/src/assets/aboutpage-images/About-Hero-Banner.svg');
    background-size: cover;
    background-position: 0px -100px;
    background-repeat: no-repeat;
    min-height: 465px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.abt-hero-wrap {
    margin-top: 4rem;
}
.abt-hero-heading {
    color: var(--BLACK, #2B2E2B);
    text-align: center;

    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 2rem;
}
.abt-hero-txt {
    color: var(--BLACK, #2B2E2B);

    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 172%;
    max-width: 568px;
    width: 100%;
    text-align: center;
    margin: auto;
}
.abt-hero-txt-spn {
    font-weight: 600;
}

/* hero section end */
/* understant section start */
.understand-section {
    padding: 3rem 4rem;
}
.understand-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
}
.understand-card {
    max-width: 497px;
    width: 100%;
    min-height: 267px;
    filter: drop-shadow(0px 4px 40px rgba(4, 79, 71, 0.05));
    padding: 2rem;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.understand-card.bg-white {
    border: 0.2px solid #3d93895c;
    background-color: #fff;
}
.understart-heading {
    color: var(--BLACK, #2B2E2B);

    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin: 0;
}
.undr-card-txt-wrap {
    min-width: 248px;
    width: 50%;
}
.undr-card-heading {
    color: var(--BLACK, #2B2E2B);

    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}
.undr-card-txt {
    color: var(--BLACK, #2B2E2B);

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%;
    margin: 0;
}
.undr-card-spn {
    color: var(--BLACK, #3D9389);
}
.undr-card-img-wrap {
    max-width: 247px;
    width: 100%;
}
.undr-card-img {
    width: 100%;
}
/* understand section end */
/* choose section start */
.choose-section {
    background-color: #fff;
    border-radius: 126px;
    margin: 1rem auto;
    padding: 4rem 0;
}
.choose-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
}
.choose-col {
    max-width: 332px;
    width: 100%;
    height: 252px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.choose-heading {
    color: var(--BLACK, #2B2E2B);

    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}
.choose-card {
    border-radius: 46px;
    width: 100%;
    height: 100%;
    padding: 2rem 1.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.choose-card.green {
    background-color: var(--GREEN, #3D9389);
    color: #fff;
}
.choose-card.orange {
    background-color: var(--ORANGE, #F97743);
    color: #fff;
}
.choose-card.blue {
    background-color: #5CD6C7;
    color: var(--BLACK, #2B2E2B);
}
.choose-card.grey {
    background-color: #F9F9F9;
    align-items: center;
    justify-content: center;
    min-width: 323px;
}
.choose-card-inner {
    width: 121px;
}
.choose-direction-icon {
    width: 100%;
}
.choose-card.yellow {
    background-color: #ECFF79;
    color: var(--BLACK, #2B2E2B);
}
.choose-card-head-wrap {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 0.8rem;
    margin-bottom: 1rem;
}
.choose-card-heading {

    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}
.choose-card-txt {

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%;
}
/* choose section end */
/* journey section start */
.journey-section {
    background-image: url('/src/assets/aboutpage-images/journey-banner.svg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 522px;
    display: flex;
    align-items: center;
    padding: 2rem 10rem;
    padding-bottom: 2rem;
    margin-top: 2rem;
}
.journey-heading {
    color: var(--GREEN, #2B2E2B);

    font-size: 46.355px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 1rem;
}
.jpurney-head-spn {
    color: var(--GREEN, #3D9389);
}
.journey-txt {
    color: var(--BLACK, #2B2E2B);

    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 166%;
}
.journey-btn-wrap {
    margin-top: 3rem;
}
/* journey section end */

@media screen and (max-width: 480px) {
    /* ******Sec 1******* */
    .about-hero-section {
        background-image: unset;
        background-color: #fff;
        padding: 3rem 1rem;
        border-radius: 60px;
        min-height: auto;
    }
    .abt-hero-heading {
        font-size: 30px;
    }
    /* ******Sec 2******* */
    .understand-section {
        padding: 2rem 1rem;
    }
    .understand-card.heading {
        min-height: auto;
        padding-bottom: 0rem;
    }
    .understart-heading {
        font-size: 30px;
    }
    /* ******Sec 3******* */
    .choose-section {
        border-radius: 60px;
        padding: 2rem 0;
    }
    .choose-col.heading {
        height: auto;
    }
    .choose-col.heading .choose-card {
        justify-content: start;
    }
    .choose-heading {
        font-size: 34px;
    }
    /* ******Sec 4******* */
    /* ******Sec 5******* */
    .journey-section {
        background-image: unset;
        background-color: #fff;
        padding: 2rem 1rem;
        height: auto;
        text-align: center;
        flex-direction: column;
    }
    .journey-heading {
        font-size: 30px;
    }
    .journey-txt {
        font-size: 18px;
    }
    .journey-btn-wrap {
        margin-top: 2rem;
    }

}
@media screen and (min-width: 1920px){
    .about-hero-section {
        background-position-y: bottom;
    }
    .choose-col {
        max-width: 465px;
    }
}
/* ------About CSS End-------- */

/* ------Services CSS Start-------- */
.services-section {
    background-image: url('/src/assets/services-images/services-background.svg');
    background-color: #F7F7F8;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 140px;
    padding: 3rem 2rem 4rem 2rem;
    text-align: center;
}
.services-heading {
    color: var(--BLACK, #2B2E2B);

    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 156%;
    margin-bottom: 2rem;
    display: none;
}
.services-inner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}
.services-card {
    border-radius: 34px;
    background: #FFF;
    padding: 2rem 3rem 3rem;
    max-width: 512px;
    width: 100%;
    box-shadow: 0px -12.854px 50px 0px rgba(9, 83, 74, 0.08);
}
.services-img {
    margin-bottom: 2.5rem;
    width: 100%;
    display: none;
}
.services-card-heading {
    color: var(--BLACK, #2B2E2B);

    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 1rem;
}
.services-ul {
    color: var(--BLACK, #2B2E2B);

    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px;
    list-style: none;
    text-align: start;
}
.services-ul li {
    position: relative;
}
.services-li-spn {
    position: absolute;
    left: -33px;
    top: -2px;
}
.services-li-spn.last {
    left: -15px;
}
.services-li-wrap {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.services-sub-ul {
    color: var(--BLACK, #2B2E2B);

    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 174%;
    list-style: disc;
    margin-top: 0.5rem;
    padding-left: 1rem;
}
.serv-sub-li-wrap {
    display: flex;
    align-items: center;
    gap: 3rem;
}
.services-form-wrapper {
    text-align: start;
}
.serv-form-lbl {
    color: var(--BLACK, #2B2E2B);

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: block;
    margin-bottom: 0.9rem;
}
.serv-form-field-wrap select, input {
    border-radius: 8px;
    border: 1px solid #D7D7D7;
    width: 100%;
    height: 46px;
    padding: 0.5rem;
    margin-bottom: 0.9rem;
    color: var(--BLACK, #2B2E2B);

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.serv-form-field-wrap input::placeholder {
    color: #909390;
}
.form-submit-btn {
    color: #FFF;

    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    text-decoration: none;
    border-radius: 12px;
    background: var(--ORANGE, #F97743);
    height: 58px;
    display: flex;
    padding: 0.5rem 1rem;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}
@media screen and (max-width: 480px) {
    .services-heading {
        font-size: 30px;
    }
    .services-inner-wrapper {
        flex-direction: column;
    }
    .services-card {
        border-radius: 30px;
        padding: 2rem 1.5rem;
    }
    .services-card-heading {
        font-size: 20px;
        margin-bottom: 0.5rem;
    }
    .services-ul {
        font-size: 16px;
    }
    .services-sub-ul {
        padding-left: 1rem;
    }
    .serv-sub-li-wrap {
        display: block;
    }
    .serv-form-lbl, .serv-form-field-wrap select, input {
        font-size: 14px;
    }
    .form-submit-btn {
        font-size: 18px;
        height: 49px;
    }
    .services-card {
        min-height: auto;
    }
}
/* ------Services CSS End-------- */

/* ------Portfolio CSS Start-------- */
/* *****section 1 start */
.portfolio-page {
    background-color: #F9F9F9;
}
/* *****section 1 end */
/* ------Portfolio CSS End-------- */

/* ------Case Studies CSS Start-------- */
/* hero section start */
.caseStudies-page {
    background-color: #F7F7F8;
}
.case-tab-cover {
    opacity: 0.9;
    background: #FFF;
    height: 61px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    position: fixed;
    width: 100%;
    z-index: 1;
}
.case-tab-link-wrap {
    padding: 1.1rem 0;
}
.case-tab-link-wrap.active {
    border-bottom: 2px solid #F97743;
}
.case-tab-link-wrap.active a {
    color: #F97743;
}
.case-tab-cover a {
    color: #2B2E2B;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 114%;
    text-decoration: none;
}
.case-hero-section {
    /* border-radius: 104px 104px 0px 0px; */
    background: linear-gradient(180deg, #DDF9F6 0%, #F7F7F8 100%);
}
.case-hero-wrap1 {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    padding-top: 7rem;
    padding-bottom: 0;
}
.width-90 {
    width: 90%;
    margin: auto;
}
.case-hero-heading {
    color: #3D9389;

    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.case-hero-txt {
    color: #000;

    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 38px;
}
.case-hero-txt-spn {
    font-weight: 600;
}
.case-hero-wrap2 {
    padding: 0rem 1rem 3rem;
}
.case-hero-card-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}
.case-hero-card {
    border-radius: 14px;
    background: #FFF;
    max-width: 329px;
    width: 100%;
    height: 300px;
    padding: 2rem;
}
.case-hero-card-txt {
    color: #000;

    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 29px;
    margin: 0;
}
.case-hero-card-txt span {
    font-weight: 600;
}
.case-hero-card img {
    margin-bottom: 1rem;
}
/* hero section end */
/* integration section start */
.integration-section {
    background-color: #F7F7F8;
    padding-bottom: 3rem;
}
.inte-col-txt {
    color: #000;

    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 164%;
}
.inte-col-txt span {
    font-weight: 600;
}
.integration-heading {
    color: #2B2E2B;

    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 2rem;
    text-align: center;
}
.integration-col-wrap {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 3.19rem;
}
.integration-col {
    max-width: 225px;
    width: 100%;
    height: 238px;
}
.inte-col-img {
    width: 100%;
    margin-bottom: 1rem;
}
/* integration section end */
/* integ work section start */
.integ-work-section {
    border-radius: 50px;
    background-color: #FFF;
    padding: 2rem;
}
.integ-heading-wrap {
    display: flex;
    align-items: center;
    margin: 2rem auto;
    padding: 0rem 0.7rem 2rem;
}
.integ-heading {
    color: #2B2E2B;

    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    max-width: 371px;
    width: 50%;
}
.integ-txt {
    color: #000;

    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
    margin: 0;
    max-width: 555px;
    width: 50%;
}
.integ-col-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.1rem;
}
.integ-col {
    border-radius: 10px;
    border: 1px solid #CEF1ED;
    background: #EEF8F7;
    box-shadow: 0px 4px 40px 0px rgba(45, 199, 109, 0.05);
    max-width: 328px;
    width: 100%;
    height: 309px;
    padding: 2.5rem 1.5rem 1.5rem;
    position: relative;
}
.integ-col-num {
    border-radius: 5.091px;
    background-color: #fff;
    border: 5px solid #E5F5F4;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -27px;
    color: #00BF22;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.integ-col-txt {
    color: #3D474D;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    margin: 0;
}
.integ-col-txt span {
    font-weight: 600;
}
.integ-col-img-wrap {
    text-align: center;
}
/* integ work section end */
/* integ api section start */
.integ-api-wrap {
    background-image: url('/src/assets/case-studies-images/CTO-banner.svg');
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 352px;
    max-width: 1040px;
    padding: 2rem 5rem;
    margin: 3rem auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.integ-api-heading {
    color: #2B2E2B;

    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.integ-api-btn-wrap {
    margin-top: 2rem;
}
/* integ api section end */
/* develop section start */
.develop-col-cover {
    margin-top: 4rem;
}
.develop-col-inner-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    width: 91%;
    margin: auto;
}
.develop-heading-col {
    max-width: 433px;
    min-height: 557px;
    background-color: #FFF;
    border: 1px solid #F97743;
    border-radius: 12px;
    padding: 3rem;
}
.develop-heading {
    color: #2B2E2B;

    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 114%;
    margin-bottom: 2rem;
}
.develop-heading span {
    color: #F97743;
}
.develop-txt {
    color: #2B2E2B;

    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 166%;
    margin: 0;
}
.develop-card-col {
    display: flex;
    flex-wrap: wrap;
    max-width: 830px;
    gap: 1rem;
}
.develop-col-txt {
    color: #2B2E2B;

    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 166%;
    margin: 0;
}
.develop-card {
    border-radius: 12px;
    background: #FFF;
    padding: 2rem;
    width: 100%;
}
.develop-card h5 {
    color: #000;

    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 166%;
}
.develop-card p {
    color: #000;

    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 166%;
    margin: 0;
}
.develop-card img {
    margin-bottom: 1rem;
}
.develop-card.one {
    max-width: 386px;
    height: 234px;
}
.develop-card.two {
    max-width: 260px;
    height: 283px;
}
.develop-card.three {
    max-width: 336px;
    height: 264px;
}
.develop-card {
    max-width: 308px;
    height: 264px;
}
/* develop section end */
/* startup section start */
.benefit-startup-cover {
    margin-top: 2rem;
}
.startup-heading {
    color: #000;

    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 166%;
    text-align: center;
    margin-bottom: 1.5rem;
}
.startup-inner-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.startup-card {
    border-radius: 12px;
    background: #FFF;
    max-width: 254px;
    height: 297px;
    padding: 2rem;
}
.startup-icon-wrap {
    margin-bottom: 1rem;
}
.startup-card-heading {
    color: #000;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 166%;
}
.startup-card-txt {
    color: #000;

    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 166%;
    margin: 0%;
}
/* startup section end */
/* go section start */
.go-wrap {
    background-image: url('/src/assets/case-studies-images/go-custome-banner.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    min-height: 352px;
    max-width: 1040px;
    padding: 2rem 5rem;
    margin: 3rem auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.go-heading {
    color: #2B2E2B;

    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
}
.go-txt {
    color: #000;

    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 173%;
    margin: 1rem 0 2rem;
}
/* go section end */
/* team section start */
.team-section {
    background: linear-gradient(180deg, #FFF 0%, #F7F7F8 105.04%, #8A9831 105.05%, #FFF 105.05%);
}
.team-inner-wrap {
    padding: 3.5rem 1rem 1rem;
}
.team-cover {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6.6rem;
    margin: auto;
}
.team-txt-wrap {
    max-width: 630px;
}
.team-heading {
    color: #2B2E2B;

    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 114%;
    background-image: url('/src/assets/case-studies-images/tech-team-highlight.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: 0px;
    padding: 0 1rem;
}
.team-txt1 {
    color: #000;

    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 166%;
    padding: 0 1rem;
}
.team-txt2 {
    color: #000;

    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 166%;
    padding: 0 1rem;
}
.team-txt2 span {
    font-weight: 600;
}
.team-card {
    background-color: #FFF;
    filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.05));
    border-radius: 12px;
    padding: 1.6rem;
    margin: 1rem 0;
}
.team-card-txt-wrap h6 {
    color: #000;

    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 166%;
    margin: 0;
}
.team-card-txt-wrap p {
    color: #000;

    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 166%;
    margin: 0;
}
.team-card.rct {
    max-width: 392px;
    min-height: 178px;
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 1rem;
}
.team-card.sqr {
    max-width: 329px;
    min-height: 294px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
}
.team-card-cover {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;
}
/* team section end */
/* feature section start */
.feature-section {
    background: linear-gradient(360deg, #FFF 0%, #F7F7F8 105.04%, #8A9831 105.05%, #FFF 105.05%);
    padding: 1rem 1rem 2rem;
}
.feature-heading {
    color: #000;

    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 166%;
    text-align: center;
    margin-bottom: 2rem;
}
.feature-inner-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.feature-card {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);
    padding: 1.7rem;
    max-width: 206px;
    min-height: 305px;
    width: 100%;
}
.feature-icon-wrap {
    margin-bottom: 1.5rem;
}
.feature-card-heading {
    color: #000;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%;
}
.feature-card-txt {
    color: #000;

    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 166%;
    margin: 0;
}
/* feature section end */
/* potential section start */
.potential-wrap {
    background-image: url('/src/assets/case-studies-images/product-idea-banner.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    min-height: 352px;
    max-width: 1040px;
    padding: 2rem 5rem;
    margin: 3rem auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.potential-heading {
    color: #000;

    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 114%;
}
.potential-txt {
    color: #000;

    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 166%;
    margin: 1rem 0rem 2rem;
}
/* potential section end */
@media screen and (max-width: 480px) {
    .width-90 {
        width: 100%;
    }
    .case-tab-cover {
        position: initial;
        display: block;
        text-align: center;
        height: auto;
    }
    .case-tab-link-wrap {
        padding: 0.3rem 0;
    }
    .case-hero-wrap1 {
        flex-direction: column;
        text-align: center;
        padding-top: 2rem;
    }
    .case-hero-img-wrap {
        width: 100%;
    }
    .case-hero-txt {
        text-align: center;
    }
    .case-hero-card-wrap {
        flex-direction: column;
    }

    .integration-col-wrap {
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
    }
    .integ-work-section {
        padding: 2rem 1rem;
    }
    .integ-heading-wrap {
        flex-direction: column;
        text-align: center;
    }
    .integ-heading, .integ-txt {
        width: 100%;
    }
    .integ-col-wrap {
        flex-direction: column;
        gap: 3rem;
    }
    .integ-api-wrap {
        background-image: none;
        padding: 1rem;
        margin: 1rem auto;
        text-align: center;
        min-height: auto;
    }

    .develop-col-cover {
        margin-top: 2rem;
    }
    .develop-col-inner-wrap, .startup-inner-wrap {
        flex-direction: column;
    }
    .develop-card.two, .develop-card.two, .develop-card.three, .develop-card.four {
        max-width: initial;
        height: auto;
    }
    .benefit-startup-cover {
        padding: 1rem;
        margin-top: 1rem;
    }
    .startup-card {
        max-width: none;
        height: auto;
    }
    .go-wrap {
        background-image: none;
        padding: 1rem;
        margin: 1rem auto;
        text-align: center;
        min-height: auto;
    }

    .team-inner-wrap {
        padding: 1rem;
    }
    .team-cover {
        flex-direction: column;
        gap: 1rem;
        text-align: center;
    }
    .team-heading {
        font-size: 34px;
        background-size: 65%;
        background-position-x: center;
        padding: 0;
    }
    .team-txt1, .team-txt2 {
        font-size: 18px;
        padding: 0;
    }
    .team-card-cover, .feature-inner-wrap {
        flex-direction: column;
    }
    .team-card.sqr {
        max-width: max-content;
    }
    .feature-card {
        max-width: none;
        min-height: auto;
    }
    .potential-wrap {
        background-image: none;
        padding: 1rem;
        text-align: center;
        margin: 1rem auto;
        min-height: auto;
    }
}

/* ------Case Studies CSS End-------- */
/* ------Thank you CSS Start-------- */

.thankyou-page {
    background-image: url('/src/assets/services-images/thankyou-pattern.svg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;
    background-color: #F7F7F8;
    min-height: 500px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 4rem 0;
}
.thankyou-page h1 {
    font-size: 35px;
    font-weight: 700;
    line-height: 42px;
    margin-bottom: 1rem;
}
.thankyou-page p {
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    margin-bottom: 1.5rem;
}
.thankyou-page a {
    font-size: 18px;
    font-weight: 600;
    line-height: 20.16px;
    text-decoration: none;
    color: #F97743;
    border: 1px solid;
    border-radius: 50px;
    padding: 0.65rem 1.58rem;
    display: inline-block;
    transition: all ease-in-out 0.5s;
}
.thankyou-page a:hover {
    background-color: #F97743;    
    color: #fff;
}
/* ------Thank you CSS End-------- */

/* Arabic CSS Start */
.arabic {
    direction: rtl;
}
.header a, .footer.arabic a, .footer.arabic p,
.homepage.arabic h1, .homepage.arabic h2, .homepage.arabic h3, .homepage.arabic h4, 
.homepage.arabic h5, .homepage.arabic h6, .homepage.arabic p, .homepage.arabic a, .homepage.arabic li,
.aboutpage.arabic h1, .aboutpage.arabic h2, .aboutpage.arabic h3, .aboutpage.arabic h4, 
.aboutpage.arabic h5, .aboutpage.arabic h6, .aboutpage.arabic p, .aboutpage.arabic a,
.servicepage.arabic h1, .servicepage.arabic h2, .servicepage.arabic h3, .servicepage.arabic h4, 
.servicepage.arabic h5, .servicepage h6, .servicepage.arabic p, .servicepage.arabic a,
.servicepage.arabic li, .servicepage.arabic input, .servicepage.arabic select,
.portfolio-page.arabic h1, .portfolio-page.arabic h2, .portfolio-page.arabic h3, .portfolio-page.arabic h4, 
.portfolio-page.arabic h5, .portfolio-page.arabic h6, .portfolio-page.arabic p, .portfolio-page.arabic a,
.caseStudies-page.arabic h1, .caseStudies-page.arabic h2, .caseStudies-page.arabic h3, 
.caseStudies-page.arabic h4, .caseStudies-page.arabic h5, .caseStudies-page.arabic h6, 
.caseStudies-page.arabic p, .caseStudies-page.arabic a, a.btn.language-btn,
.thankyou-page.arabic h1, .thankyou-page.arabic p, .thankyou-page.arabic a
{
    font-family: 'Alexandria' !important;
}
.arabic a.btn.language-btn {
    font-family: 'BRFirma' !important;
}
/* ------Header Arabic CSS Start-------- */
.arabic .nav-inner-wrapper {
    margin-left: 4rem;
    margin-right: 0rem;
}
.arabic .nav-menu, .arabic .custom-menu-btn {
    right: auto;
    left: 0;
}
@media screen and (max-width: 480px) {
    .arabic .nav-inner-wrapper {
        margin: 0;
    }
}
/* ------Header Arabic CSS End-------- */
/* ------Footer Arabic CSS Start-------- */
.arabic .footer-text {
    line-height: 23px;
}
/* ------Footer Arabic CSS End-------- */
/* ------Homepage Arabic CSS Start-------- */
.arabic .hero-heading {
    line-height: 151%;
}
.arabic .what-we-do-heading, .arabic .bound-col-heading {
    font-weight: 600;
}
.arabic .what-we-do-txt {
    font-weight: 300;
}
/* .arabic .wedo-card-link-wrap {
    left: 51px;
    right: auto;
} */
.arabic .craft-heading-spn {
    font-weight: 300;
}
.arabic .craft-card-txt {
    line-height: 38px;
    font-weight: 300;
}
.arabic .craft-link-wrap {
    right: auto;
    left: 3rem;
}
.arabic .bound-col-txt {
    font-size: 14px;
}
.arabic .offices-li-spn {
    left: auto;
    right: -40px;
}
.arabic .offices-list {
    font-size: 24px;
    font-weight: 400;
    list-style: none;
    padding-left: 0rem;
    padding-right: 3rem;
}
.arabic .challange-section{
    background-image: url('/src/assets/homepage-images/challenging-banner-ar.svg');
}
.arabic .challenge-head-wrap {
    display: block;
}
.arabic .start-now-btn::after {
    content: url('/src/assets/homepage-images/circle-arrow-ar.svg');
    right: auto;
    left: -64px;
}
.arabic .craft-card-heading {
    font-size: 18px;
}
.arabic .craft-card {
    padding: 2.5rem 2rem 2rem;
}
/* ------Homepage Arabic CSS End-------- */
/* ------Aboutpage Arabic CSS Start-------- */
.arabic .understart-heading {
    font-size: 40px;
    line-height: 184%;
}
.arabic .undr-card-heading {
    font-size: 16px;
    line-height: 184%;
}
.arabic .undr-card-txt {
    font-size: 16px;
    font-weight: 300;
    line-height: 27px;
}
.arabic .choose-heading {
    line-height: 173%;
}
.arabic .choose-card-txt {
    font-size: 16px;
    font-weight: 300;
    line-height: 176%;
}
.arabic .journey-section {
    background-image: url('/src/assets/aboutpage-images/journey-banner-ar.svg');
}
.arabic .journey-heading {
    line-height: 164%;
}
.arabic .journey-txt {
    line-height: 242%;
}
/* ------Aboutpage Arabic CSS End-------- */
/* ------servicespage Arabic CSS Start-------- */
.arabic .services-card {
    padding: 2rem 2rem 3rem;
}
.arabic .services-ul {
    font-size: 16px;
    font-weight: 300;
    line-height: 35px;
    padding-left: 0;
    padding-right: 32px;
}
.arabic .services-li-spn {
    left: auto;
    right: -33px;
    top: 2px;
}
.arabic .services-li-wrap {
    display: flex;
    align-items: center;
    gap: 3.5rem;
}
.arabic .services-sub-ul {
    padding-left: 0;
    padding-right: 16px;
    font-weight: 300;
}
.arabic .serv-form-field-wrap select, input {
    font-weight: 300;
}
@media screen and (max-width: 480px) {
    .arabic .intro-heading, .arabic .craft-heading, .arabic .craft-card-heading {
        line-height: 160%;
    }
    .arabic .services-li-wrap {
        flex-direction: column;
        align-items: self-start;
        gap: unset;
    }
}
/* ------servicespage Arabic CSS End-------- */
/* ------case studies page Arabic CSS Start-------- */
.arabic .case-tab-cover {
    gap: 5rem;
}
.arabic .integ-api-wrap {
    background-image: url('/src/assets/case-studies-images/CTO-banner-ar.svg');
}
.arabic .potential-wrap {
    background-image: url('/src/assets/case-studies-images/product-idea-banner-ar.svg');
}
.arabic .go-wrap {
    background-image: url('/src/assets/case-studies-images/go-custome-banner-ar.svg');
}
.arabic .team-card.rct {
    min-height: 191px;
}
.arabic .inte-col-txt {
    line-height: 182%;
}
.arabic .integ-heading {
    line-height: 58px;
}
.arabic .integ-api-heading {
    line-height: 62px;
}
.arabic .develop-heading, .arabic .startup-card-txt {
    line-height: 173%;
}
.arabic .develop-col-txt {
    margin-bottom: 1rem;
}
.arabic .develop-txt {
    line-height: 186%;
}
.arabic .develop-card h5 {
    font-size: 18px;
    line-height: 179%;
}
.arabic .develop-card p {
    line-height: 179%;
}
.arabic .go-heading {
    line-height: 166%;
}
.arabic .go-txt {
    line-height: 191%;
}
.arabic .team-heading {
    font-size: 37px;
    background-size: 35%;
    background-position-x: 270px;
    padding-left: 0;
    padding-right: 1rem;
}
.arabic .team-txt1 {
    font-weight: 300;
    line-height: 198%;
    padding-right: 1rem;
    padding-left: 0;
}
.arabic .team-txt2 {
    line-height: 225%;
}
.arabic .feature-card {
    padding: 1.3rem;
}
.arabic .team-card-txt-wrap h6 {
    margin-bottom: 1rem;
}
.arabic .team-card-txt-wrap p {
    line-height: 185%;
}
.arabic .feature-card-txt {
    line-height: 199%;
}
.arabic .potential-heading {
    font-weight: 600;
    line-height: 155%;
}
.arabic .potential-txt {
    line-height: 182%;
}
@media screen and (max-width: 480px) {
    .arabic .integ-api-wrap, .arabic .go-wrap, .arabic .potential-wrap, 
    .arabic .journey-section, .arabic .challange-section {
        background-image: none;
    }
    .arabic .team-heading {
        font-size: 34px;
        background-size: 62%;
        background-position-x: center;
        line-height: 150%;
        padding-left: 0;
        padding-right: 0;
    }
}
/* ------case studies page Arabic CSS End-------- */
/* ------portfolio page Arabic CSS End-------- */
.arabic .fl-card-description {
    padding: 2.875rem;
}
.arabic .fl-card-covers p {
    line-height: 189%;
    max-width: 371px;
}
.arabic .fl-app-card-img-wrap img {
    left: -35px;
    bottom: -50px;
    right: auto;
}
.arabic .clinic-card-web-design img {
    left: -40px;
    bottom: -40px;
    right: auto;
}
.clinic-card-description p {
    max-width: 410px;
    line-height: 190%;
    margin-top: 1.5rem;
}
.arabic .concrete-card-web-design img {
    left: 25px;
    right: auto;
}
.arabic .concrete-card-description {
    padding: 2.3rem;
}
.arabic .concrete-card-description p {
    line-height: 191%;
    max-width: 371px;
}
.arabic .toptech-card-web-design img {
    top: -13px;
    left: -60px;
    right: auto;
}
.arabic .api-card-description h3 {
    line-height: 39px;
}
.arabic .api-card-description p {
    max-width: 353px;
    line-height: 184%;
}
.arabic .api-card-country {
    padding: 1.875rem;
}
/* ------portfolio page Arabic CSS End-------- */
@media screen and (max-width: 410px) {
    .wedo-card-link-wrap, .craft-link-wrap {
        position: unset;
        text-align: end;
    }
}
/* Arabic CSS End */


