.clinic-section {
    padding: 3rem;
    border-radius: 50px;
    background-color: #F2F2F2;
}
.clinic-heading, .clinic-card-covers h3 {
    color: #000;

    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.clinic-txt {
    color: #000;

    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 0;
}
.clinic-website-wrap {
    color: #2B2E2B;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: end;
}
.clinic-website-wrap a {
    color: #2B2E2B;

    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
}
.clinic-card-covers h4 {
    color: #000;

    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.clinic-card-covers p {
    color: #000;

    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 171%;
    margin: 0;
}
.clinic-card-covers {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 2rem auto;
}
.clinic-card-logo, .clinic-card-duration, .clinic-card-country,
.clinic-card-service, .clinic-card-description, .clinic-card-web-design {
    border-radius: 14px;
    background: #FFF;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);
    padding: 1.875rem;
    width: 100%;
    overflow: hidden;
    position: relative;
}
.clinic-card-logo {
    width: 236px;
    min-height: 118px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.clinic-card-logo img {
    width: 100%;
}
.clinic-card-duration {
    width: 236px;
    min-height: 206px;
}
.clinic-card-duration img {
    margin-bottom: 1rem;
}
.clinic-card-country {
    width: 256px;
    min-height: 206px;
}
.clinic-card-country img {
    margin-bottom: 1.8rem;
}
.clinic-card-web-design {
    width: 492px;
    min-height: 206px;
}
.clinic-card-web-design img {
    position: absolute;
    right: 0;
    bottom: 0;
}
.clinic-card-service {
    width: 234px;
    min-height: 279px;
}
.clinic-services-img {
    width: 100%;
    margin-top: 2rem;
}
.clinic-card-description {
    width: 511px;
    min-height: 333px;
}
.clinic-card-app {
    width: 234px;
    height: 256px;
    padding: 1.875rem;
    border-radius: 14px;
    background-color: #F97743;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);
    position: relative;
    overflow: hidden;
}
.clinic-app-card-img-wrap img {
    position: absolute;
    right: -10px;
    bottom: -20px;
}
.clinic-appstore-wrap {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 0.4rem;
}
.clinic-card-app h4 {
    color: #FFF;
}
.clinic-cardCol {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}
.clinic-log-dur-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}
.cl-sub-cardCol {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.cl-web-country-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}


@media screen and (max-width: 480px) {
    .clinic-section {
        padding: 3rem 1rem;
    }
    /* .width-90 {
        width: 100%;
    } */
    .clinic-card-covers, .cl-sub-cardCol, .cl-web-country-wrap {
        flex-direction: column;
    }
    .clinic-card-logo, .clinic-card-service, .clinic-card-duration, .clinic-card-country, .clinic-card-description,
    .clinic-card-web-design, .clinic-card-app {
        width: auto;
    }
    .clinic-card-duration {
        min-height: 205px;
    }
}


