.concrete-section {
    padding: 3rem;
}
.concrete-heading, .concrete-card-covers h3 {
    color: #000;

    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.concrete-txt {
    color: #000;

    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 0;
}
.concrete-card-covers {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 2rem auto;
}
.concrete-card-logo, .concrete-card-duration, .concrete-card-country,
.concrete-card-service, .concrete-card-description, .concrete-card-des-heading,
.concrete-card-web-design {
    border-radius: 14px;
    background: #FFF;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);
    padding: 1.875rem;
    width: 100%;
    position: relative;
    overflow: hidden;
}
.con-logo-serve-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.con-logo-head-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}
.concrete-card-logo {
    width: 234px;
    min-height: 187px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.concrete-card-logo img {
    width: 100%;
}
.concrete-card-duration {
    width: 245px;
    min-height: 255px;
}
.concrete-card-duration img {
    margin-bottom: 1rem;
}
.concrete-card-country {
    width: 256px;
    min-height: 255px;
}
.concrete-card-country img {
    margin-bottom: 1.8rem;
}
.concrete-card-web-design {
    width: 511px;
    min-height: 282px;
}
.concrete-card-web-design img {
    position: absolute;
    bottom: 0;
    right: 60px;
}
.concrete-card-service {
    width: 234px;
    min-height: 304px; 
}
.concrete-services-img {
    width: 100%;
    margin-bottom: 2rem;
}
.concrete-card-description {
    width: 479px;
    min-height: 233px;
}
.concrete-card-app {
    width: 236px;
    min-height: 378px;
}
.concrete-website-wrap {
    color: #2B2E2B;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: end;
}
.concrete-website-wrap a {
    color: #2B2E2B;

    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
}
.duration-outer-wrap {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
}
.concrete-cardCol {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}
.concrete-card-covers h4 {
    color: #000;

    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.concrete-card-covers p {
    color: #000;

    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 171%;
    margin: 0;
}


@media screen and (max-width: 480px) {
    .concrete-section {
        padding: 3rem 1rem;
    }
    /* .width-90 {
        width: 100%;
    } */
    .concrete-card-covers, .con-logo-serve-wrap {
        flex-direction: column;
    }
    .concrete-card-logo, .concrete-card-service, .concrete-card-duration, .concrete-card-country, .concrete-card-description,
    .concrete-card-web-design, .concrete-card-app {
        width: auto;
        min-height: auto;
    }
    .concrete-card-country img {
        margin-bottom: 1.5rem;
    }
}

